<template>
  <section class="chart-field is-flex mt-4">
    <div class="chart-container is-relative">
      <div class="chart-title has-text-centered is-flex is-flex-direction-column is-justify-content-center">
        <div class="has-text-blue has-text-weight-semibold total-distribution mb-1">{{totalOfDistribution | currencyFormatter}}</div>
        <div class="desc">{{prefix}} return</div>
      </div>
      <div :id="`${distributionType}-distribution-chart`" style="height: 100%;"></div>
    </div>
    <div class="caption is-flex is-flex-direction-column is-justify-content-center">
      <div
        v-for="(item, index) in convertedPercentages"
        :key="item + index"
        :class="`has-text-left breakdown breakdown-${index} ${index < convertedPercentages.length - 1 ? 'mb-3' : ''}`"
      >{{item}}% {{breakDown[index]}}</div>
    </div>
  </section>
</template>
<script>
import { roundPercents } from '@utils/common-methods/common'
import * as echarts from 'echarts/core'
import { TooltipComponent } from 'echarts/components'
import { PieChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import { truncate } from '@utils/common-methods/numberFormatter'

echarts.use([
  TooltipComponent,
  PieChart,
  CanvasRenderer,
])

export default {
  props: {
    distributionData: {
      required: true,
    },
    // 3 possible types: individual-latest, individual-total (portfolio) and
    // latest all investors combined (owners day modal which is not passing a type prop)
    distributionType: {
      type: String,
      default: '',
    },
    breakDown: {
      type: Array,
      default: () => {
        return ['Rental Income', 'Return of Capital', 'Capital Gains', 'Business Income']
      }
    },
  },
  computed: {
    prefix() {
      return this.distributionType === '' ? 'combined' : 'your'
    },
    breakDownData() {
      const { rentalIncome, returnOfCapital, capitalGains, businessIncome } = this.distributionData || {}
      return [Number(rentalIncome), Number(returnOfCapital), Number(capitalGains), Number(businessIncome)]
    },
    convertedPercentages() {
      return roundPercents(this.breakDownData)
    },
    chartData() {
      return this.breakDownData.map((data, index) => {
        return { value: truncate(data), name: this.breakDown[index] }
      })
    },
    totalOfDistribution() {
      return this.breakDownData.reduce((pre, cur) => pre + cur)
    },
  },
  filters: {
    currencyFormatter(value) {
      const number = Number(truncate(value))
      return number.toLocaleString('en-CA',
        { style: 'currency', currency: 'CAD' }
      )
    },
  },
  mounted() {
    this.$nextTick(() => this.initChart())
  },
  methods: {
    initChart() {
      const myChart = echarts.init(document.getElementById(`${this.distributionType}-distribution-chart`))
      myChart.setOption({
        tooltip: {
          trigger: 'item',
          confine: true,
          formatter: '{b}: $' + '{c}',
        },
        series: [
          {
            type: 'pie',
            radius: ['78%', '92%'],
            center: ['50%', '50%'],
            label: {
              show: false,
              position: 'center'
            },
            data: this.chartData,
            color: ['#F0EEF8', '#D1C9E4', '#B6AECA', '#9185AE'],
            emphasis: {
              itemStyle: {
                color: 'inherit',
              },
            },
          }
        ]
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

@keyframes slide-up-0 {
  0% {
    transform: translateY(140px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-up-1 {
  0% {
    transform: translateY(140px);
    opacity: 0;
  }
  50% {
    transform: translateY(140px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-up-2 {
  0% {
    transform: translateY(140px);
    opacity: 0;
  }
  66% {
    transform: translateY(140px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-up-3 {
  0% {
    transform: translateY(140px);
    opacity: 0;
  }
  75% {
    transform: translateY(140px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.chart-field {
  .chart-container {
    width: 46%;
    height: 170px;
    .chart-title {
      height: 100%;
      .total-distribution {
        font-size: 20px;
      }
      .desc {
        font-size: 14px;
      }
    }
    div[id*="distribution-chart"] {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  .caption {
    flex: 1;
    padding-left: 8px;
    overflow: hidden;
    .breakdown {
      font-size: 16px;
      &.breakdown-0 {
        animation: slide-up-0 .25s ease-out;
      }
      &.breakdown-1 {
        animation: slide-up-1 .5s ease-out;
      }
      &.breakdown-2 {
        animation: slide-up-2 .75s ease-out;
      }
      &.breakdown-3 {
        animation: slide-up-3 1s ease-out;
      }
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .chart-field .chart-container {
    width: 56%;
  }
}
</style>
